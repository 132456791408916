@use "../../../scss/Colors.module.scss";
@use "../../../scss/Common.module.scss";
@use "../../../scss/Breakpoints.module.scss";

.edgeMargin {
    // flex-direction: row;
    padding-top: 40px;
    @include Breakpoints.for-tablet-portrait-up {
        flex-direction: row;
        align-items: flex-start;
    }
}

.section {
    background-color: Colors.$blue;
    padding: 50px 0px 0px;
}

.header {
    color: Colors.$white;
}

.paragraph {
    color: Colors.$white;
    max-width: 310px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    padding-right: 20px;
}

.shareBtn {
    width: fit-content;
    margin: 20px 0;
    background-color: Colors.$darkblue;
    color: Colors.$white;
}

.imageBlock {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    width: 80%;
    align-self: center;
    & > img {
        width: 100%;
    }

    @include Breakpoints.for-tablet-portrait-up {
        align-self: flex-end;
    }
}
