@use './Colors.module.scss';
@use './Breakpoints.module.scss';

html,
body {
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    overflow-y: auto;
    width: 100vw;
    overflow-x: hidden;
    background-color: Colors.$darkblue;
}

h1 {
    font-size: 3rem;
    font-weight: normal;
    white-space: pre-line;
}

h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: normal;
    white-space: pre-line;
    text-transform: uppercase;
    @include Breakpoints.for-tablet-portrait-up {
        font-size: 3rem;
        line-height: 3.5rem;
    }
}

h3 {
    font-size: 1.4rem;
    font-weight: bold;
    font-style: italic;
    white-space: pre-line;
}

h4 {
    font-size: 1rem;
    font-weight: lighter;
    white-space: pre-line;
}

p {
    font-size: 1rem;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.2rem;
    padding: 20px 0px;
    @include Breakpoints.for-tablet-portrait-up {
        font-size: 1.1rem;
    }
}

button {
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
}
