@use "../../../scss/Colors.module.scss";
@use "../../../scss/Common.module.scss";
@use "../../../scss/Breakpoints.module.scss";

.socialBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    background-color: Colors.$darkblue;
    align-items: flex-end;
    @include Breakpoints.for-tablet-portrait-up {
        height: 95px;
    }
}

.iconRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    @include Breakpoints.for-tablet-portrait-up {
        padding: 20px 50px;
    }
}

.langRow {
    color: Colors.$white;
    a {
        color: Colors.$white;
        text-transform: uppercase;
        padding: 5px;
        text-decoration: none;
        &:hover {
            fill: Colors.$legal;
        }
        &:visited {
            color: Colors.$white;
        }
    }

    font-size: 0.8rem;

    @include Breakpoints.for-tablet-portrait-up {
        font-size: 1rem;
    }
}

.socialIconLink {
    display: none;

    @include Breakpoints.for-tablet-portrait-up {
        display: block;
        margin: 0 5px;
        & > svg {
            $size: 20px;
            width: $size;
            height: $size;
            padding: 5px;
            fill: Colors.$white;
            &:hover {
                fill: Colors.$legal;
            }
        }
    }
}

.videoSection {
    position: relative;
    width: 100%;
    // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    background-color: white;
    & > div {
        // opacity: 0.5;
        height: clamp(0px, calc(100vw * 1080/1920), 675px) !important;
        opacity: 0;
        transition: opacity 1s;
    }
    video {
        height: clamp(0px, calc(100vw * 1080/1920), 675px) !important;
    }
}
// .reactPlayer {
//     width: 100%;
// }

.headline {
    color: Colors.$darkblue;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
    text-transform: uppercase;
    white-space: normal;
    margin: 0 10px;
    @include Breakpoints.for-tablet-portrait-up {
        font-size: 2.4rem;
        line-height: 3rem;
        white-space: break-spaces;
    }
}

.headlineSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 0px;
    align-items: center;
    background-color: Colors.$white;
}

.ribbonSection {
    @extend .section;
    position: absolute;
    overflow: visible;
    width: 100%;
}

.ribbonPosition {
    align-self: center;
    width: 170px;
    z-index: 10;
    transform: scale(0.53);
    @include Breakpoints.for-tablet-portrait-up {
        position: absolute;
        top: 0px;
        left: 100px;
        transform: scale(1.0);
    }
    a {
        text-decoration: none;
        color: inherit;
    }
}

.ribbon {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    align-self: center;
    width: 170px;
    height: 90px;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.svgRibbon {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 0;
}

.decathlonLogo {
    width: 100%;
    height: auto;
    max-width: 80px;
    z-index: 1;
}

.line1 {
    font-size: clamp(1rem, 50% + 16px, 2.2rem);
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    user-select: none;
    padding: 5px 0;
    letter-spacing: 0.1rem;
    z-index: 1;
}

.line2 {
    font-size: 1rem;
    font-weight: lighter;
    text-transform: uppercase;
    font-style: italic;
    user-select: none;
    z-index: 1;
}
