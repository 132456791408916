@use "../../../scss/Colors.module.scss";
@use "../../../scss/Common.module.scss";
@use "../../../scss/Breakpoints.module.scss";

.edgeMargin {
    padding-top: 80px;
    z-index: 1;
    @include Breakpoints.for-tablet-portrait-up {
        flex-direction: row;
        align-items: flex-start;
        padding-top: 130px;
    }
}

.section {
    position: relative;
    background-color: Colors.$white;
    padding: 50px 0px;
    overflow: hidden;
}

.abilityHashtag {
    display: block;
    top: 0px;
    left: 0px;
    width: clamp(1px, 100vw, 1200px);
    // margin-left: 50px;
}

.abilityHashtagLoop {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    // max-width: 100%;
    align-self: flex-start;
    z-index: 0;
}

.header {
    color: Colors.$darkblue;
}

.paragraph {
    color: Colors.$copy;
    max-width: 310px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    padding-right: 20px;
}

.imageBlock {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;

    & > img {
        width: 100%;
    }
}
