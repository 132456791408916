@use "../../../scss/Colors.module.scss";
@use "../../../scss/Common.module.scss";

.edgeMargin {
    align-items: center;
}

.section {
    background-color: Colors.$darkblue;
    padding: 50px 0px;
}

.decathlonLogo {
    width: 100%;
    max-width: 200px;
    padding: 20px 0px;
}

.iconRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0px;
}

.socialIconLink {
    margin: 0 5px;
    & > svg {
        $size: 30px;
        width: $size;
        height: $size;
        padding: 5px;
        fill: Colors.$white;
        &:hover {
            fill: Colors.$legal;
        }
    }
}

.legal {
    color: Colors.$legal;
    text-align: center;
    padding: 10px 0px;
    max-width: 860px;
}

.note {
    color: Colors.$white;
    text-align: center;
    a {
        color: Colors.$white;
        text-decoration: none;
    }
}
