@use "../../../scss/Colors.module.scss";
@use "../../../scss/Common.module.scss";
@use "../../../scss/Breakpoints.module.scss";

.edgeMargin {
    // flex-direction: column;
    align-items: center;

    @include Breakpoints.for-tablet-portrait-up {
        flex-direction: row;
        align-items: flex-start;
    }
}

.section {
    background-color: Colors.$darkblue;
    padding: 50px 0px;
    width: 100%;
}

.header {
    color: Colors.$white;
}

.paragraph {
    color: Colors.$grey;
    max-width: 380px;
}
.speader {
    width: 100vw;
}

.textBlock {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    padding-right: 20px;
}

.imageBlock {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    align-self: center;
    justify-content: center;
    height: auto;
    & > svg {
        max-height: 100%;
        height: 100%;
    }
}

.downloadBtn {
    background-color: Colors.$white;
    color: Colors.$darkblue;
    width: fit-content;
}

.downloadBtnRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    @include Breakpoints.for-tablet-portrait-up {
        padding-top: 0px;
    }
}
.contentRow {
    display: flex;
    flex-direction: column;
    height: auto;
    // align-items: flex-start;
    width: 100%;
}
