@use "Breakpoints.module.scss";

.edgeMargin {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 40px;
    @include Breakpoints.for-tablet-portrait-up {
        margin: 0 80px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}
